import _unistUtilVisit from "unist-util-visit";
var exports = {};
var visit = _unistUtilVisit;
exports = getDefinitionFactory;
var own = {}.hasOwnProperty; // Get a definition in `node` by `identifier`.

function getDefinitionFactory(node, options) {
  return getterFactory(gather(node, options));
} // Gather all definitions in `node`


function gather(node) {
  var cache = {};

  if (!node || !node.type) {
    throw new Error("mdast-util-definitions expected node");
  }

  visit(node, "definition", ondefinition);
  return cache;

  function ondefinition(definition) {
    var id = normalise(definition.identifier);

    if (!own.call(cache, id)) {
      cache[id] = definition;
    }
  }
} // Factory to get a node from the given definition-cache.


function getterFactory(cache) {
  return getter; // Get a node from the bound definition-cache.

  function getter(identifier) {
    var id = identifier && normalise(identifier);
    return id && own.call(cache, id) ? cache[id] : null;
  }
}

function normalise(identifier) {
  return identifier.toUpperCase();
}

export default exports;